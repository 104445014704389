<template>
  <b-card title="DO List ">
    <b-modal
      id="promptDownload"
      ref="modal"
      v-model="promptDownload"
      title="Download"
      hide-footer
    >
      <strong v-if="inventoryToDownload.length <= 0">Loading Data...</strong>
      <strong v-else>Loading data finished, total data is {{ inventoryToDownload.length }}</strong>
      <div>
        <strong> Progress {{ progressDownload }} %</strong>
      </div>
      <div style="display: table; margin: 0 auto;">
        <b-button :disabled="inventoryToDownload.length < totalRowsDownload" variant="primary" style="margin-top: 20px">
          <download-excel :data="inventoryToDownload" :fields="filter" :name="'delivery-order-report-'+new Date().getTime()+'.xls'" class="download-border">Download</download-excel>
        </b-button>
      </div>
    </b-modal>
    <b-col cols="12">
      <b-row>
        <b-col cols="5">
          Start Date
          <b-form-datepicker @input="(value) => fireFiltering('startDate', value)" :max="endDate" :min="minmin" v-model="startDate" />
        </b-col>
        <b-col cols="5">
          End Date
          <b-form-datepicker @input="(value) => fireFiltering('endDate', value)" :max="maxDate" v-model="endDate" />
        </b-col>
        <b-col cols="2">
          <b-button variant="primary" style="margin-top: 20px" @click="openDownloadModal">
            Download
          </b-button>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col cols="4">
          <b-form-input @input="(value) => customerPoFiltering(value)" v-model="customerPo" type="search" placeholder="Search by Customer PO" />
        </b-col>
        <b-col cols="4">
          <b-form-input @input="(value) => sapSoFiltering(value)" v-model="soNumber" type="search" placeholder="Search by SO Number" />
        </b-col>
        <b-col cols="4">
          <b-form-input @input="(value) => sapDoFiltering(value)" v-model="search" type="search" placeholder="Search by DO Number" />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="12">
          <v-select 
            :options="listStatus" 
            v-model="selectedStatus" 
            @input="(value) => statusFiltering(value)"
            placeholder="Select Status"
            multiple 
          ></v-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col style="margin: 15px 0 0 0" cols="12">
          <b-table
            :items="deliveryOrders"
            :fields="fields"
            :busy.sync="busyLoading"
            responsive
            show-empty
            empty-text="Data Not Found"
          >
            <template v-slot:cell(deliveryDate)="row">
              {{ dates(row.item.deliveryDate) }}
            </template>
            
             <!-- Kolom untuk menampilkan setiap item -->
            <template v-slot:cell(item)="row">
              <ul style="list-style-type: none; padding-left: 0;">
                  <li v-for="(item, index) in row.item.item" class="mb-1" :key="index">
                      {{ item }}
                  </li>
              </ul>
            </template>

            <!-- Kolom untuk menampilkan setiap qty -->
            <template v-slot:cell(qty)="row">
              <ul style="list-style-type: none; padding-left: 0;">
                  <li v-for="(qty, index) in row.item.qty" class="mb-1" :key="index">
                      {{ qty }}
                  </li>
              </ul>
            </template>

            <!-- Kolom untuk menampilkan setiap scannedBarcodeLength -->
            <template v-slot:cell(scannedBarcodeLength)="row">
              <ul style="list-style-type: none; padding-left: 0;">
                  <li v-for="(length, index) in row.item.scannedBarcodeLength" class="mb-1" :key="index">
                      {{ length }}
                  </li>
              </ul>
            </template>
            <!-- Kolom untuk menampilkan setiap returnedBarcodeLength -->
            <template v-slot:cell(returnedBarcodeLength)="row">
              <ul style="list-style-type: none; padding-left: 0;">
                  <li v-for="(length, index) in row.item.returnedBarcodeLength" class="mb-1" :key="index">
                      {{ length }}
                  </li>
              </ul>
            </template>

            <template #cell(actions)="row">
              <b-button
                @click="toDetailPage(row.item)"
                variant="primary"
                >Detail</b-button
              >
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <b-pagination :total-rows="totalRows" :per-page="perPage" @change="onPageChange" v-model="currentPage" style="float: right" />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import * as XLSX from 'xlsx'
import moment from 'moment';
import axios from '@/axios';
import lodash from 'lodash';
import { userAccess, dateFormat,convertPDFtoBase64 } from "@/utils/utils";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      fields: [
        { key: 'sapSo', label: 'SAP SO', filterable: true, sortable: true, },
        { key: 'sapDo', label: 'SAP DO', filterable: true, sortable: true },
        { key: 'customerPo', label: 'Customer PO', filterable: true, sortable: true },
        { key: 'deliveryDate', label: 'DO Date', filterable: true, sortable: true },
        { key: 'item', label: 'Item', filterable: true, sortable: true },
        { key: 'qty', label: 'QTY', filterable: true, sortable: true },
        { key: 'scannedBarcodeLength', label: 'Scan QTY', filterable: true, sortable: true },
        { key: 'returnedBarcodeLength', label: 'Return QTY', filterable: true, sortable: true },
        { key: "actions", label: "Action" },
      ],      
      filter: {
        'SAP SO' : {
          field: 'sapSo',
          callback: (value) => {
            return "'"+value
          }
        },
        'SAP DO' :  {
          field: 'sapDo',
          callback: (value) => {
            return "'"+value
          }
        },
        'Customer PO' : {
          field: 'customerPo',
          callback: (value) => {
            return "'"+value
          }
        },
        'Delivery Date' :'deliveryDate',
        "Item" : 'item',
        'Qty' : 'qty',
        'Scan QTY' :  {
          field: 'scannedBarcodeLength',
          callback: (value) => {
            return "'"+value
          }
        },
        'Return QTY' : {
          field: 'returnedBarcodeLength',
          callback: (value) => {
            return "'"+value
          }
        },
      },
      inventoryToDownload:[],
      search: '',
      soNumber: '',
      customerPo: '',
      currentPage: 1,
      perPage: 10,
      totalData: 1,

      minmin:'',
      startDate: '',
      endDate: '',
      maxDate: '',
      busyLoading: true,
      totalRows:1,
      promptDownload: false,
      totalRowsDownload : 0,
      //download numbers
      downPage:0,
      dataDownload:0,
      inventoryToDownload:[],
      listStatus: [
        "New",
        "Edit Delivery",
        "Edit in Progress",
        "Cancel Delivery",
        "Delivery Canceled",
        "Scanning in Progress",
        "Scanning Completed",
        "Delivery in Progress",
        "Fulfilled",
        "Collected",
        "Returned",
        "Waiting Acknowledgment",
        "Waiting Approval",
        "Approved",
        "Return Completed",
        "Re-Delivery",
        "Deleted",
      ],
      selectedStatus: [],
    }
  },
  mounted() {
    
  },
  computed: {
    progressDownload () {
        let downloaded = this.inventoryToDownload.length
        let dataToDownload = this.totalRowsDownload
        return Math.ceil((downloaded/dataToDownload)*(100))
    },
    ...mapGetters({
      delivery : 'report/deliveryOrder'
    }),
    deliveryOrders() {
      return this.delivery.map(order => ({
        ...order,
        item : order.DO_details.map(el => el.material),
        qty : order.DO_details.map(el => el.qty),
        scannedBarcodeLength : order.DO_details.map(el => el.scannedBarcodeLength),
        returnedBarcodeLength : order.DO_details.map(el => el.returnedBarcodeLength)
      }))
    },
    ...mapState({
      filterOrder: (state) => {
        if (state.filter.filterContainer['report-delivery-list'] !== undefined) {
          let orderFilter = state.filter.filterContainer['report-delivery-list'];
          return orderFilter;
        }

        return {};
      },
    }),
   reactiveStartDate: {
      get() {
        if(this.filterOrder.startDate === undefined){
          this.filterOrder.startDate = this.startDate
        }
      
        return this.filterOrder.startDate;
      },
      set() {
        this.updateFilterInventory({
          name: 'report-delivery-list',
          data: {
            startDate: this.filterOrder.startDate
          }
        });

      }
    },
    reactiveEndDate: {
      get() {
        if(this.filterOrder.endDate === undefined){
          this.filterOrder.endDate = this.endDate;
        }
        return this.filterOrder.endDate;
      },
      set() {
        this.updateFilterInventory({
          name: 'report-delivery-list',
          data: {
            endDate: this.filterOrder.endDate
          }
        });

      }
    },
  },
  methods: {
    ...mapActions({
      deliveryList : 'report/getDo',
      getFilterInventory: 'filter/getFilter',
      updateFilterInventory: 'filter/updateFilter'
    }),
    async openDownloadModal(){
      this.downPage = 0
      this.dataDownload = 0
      this.inventoryToDownload = []
      this.promptDownload=true;
      let page = 0
      var dataDownload=this.dataDownload
      while (dataDownload < this.totalRows) {
        console.info('total data now ',dataDownload,'all data ',this.totalRows)
        let dataProcess = []
        page++
        this.downPage = page  
         await this.deliveryList({
            startDate : this.startDate, 
            endDate : this.endDate,
            forDownload:true,
            sapDo:this.search,
            sapSo: this.soNumber,
            customerPo: this.customerPo,
            status : this.selectedStatus,
            page: page, 
            entry: 500,
          
          }).then((res) => {
        // this.warrantyToDownload=data.data;
        dataProcess=res.data.map(order => {
          const transformedOrders = order.DO_details.map(detail => {
            return {
              sapSo: order.sapSo,
              sapDo: order.sapDo,
              customerPo: order.customerPo,
              deliveryDate: order.deliveryDate,
              item : detail.material,
              qty: detail.qty,
              scannedBarcodeLength : `${detail.scannedBarcodeLength}`,
              returnedBarcodeLength : `${detail.returnedBarcodeLength}`
            }  
          })
          return transformedOrders;
        })

        // Flatten the array of arrays
        const flattenedOrders = dataProcess.flat();
        this.totalRowsDownload = flattenedOrders.length
        // split into each item 
        this.inventoryToDownload.push(...flattenedOrders)
        dataDownload += flattenedOrders.length
        this.dataDownload = dataDownload
       
      });  
    }
      

    },
    onPageChange(page) {
      this.currentPage=page;

      this.deliveryList({
        startDate : this.startDate, 
        endDate : this.endDate,
        page: this.currentPage, 
        entry: this.perPage,
        sapDo: this.search,
        sapSo: this.soNumber,
        customerPo: this.customerPo,
        status : this.selectedStatus,
       
      }).then((data) => this.processMetadata(data.metadata));
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      this.busyLoading = false;
    },
    saveFilter(key, value) { //save updated filter
      let tempFilter = this.filterOrder; 

      if (value === null) {
        tempFilter[key] = undefined;
      } else {
        tempFilter[key] = value;
      }
      
      this.updateFilterInventory({ //save to store named is "filter"
        name: 'report-delivery-list',
        data: { ...tempFilter,entry: this.perPage,page:1 }
      });
    },
    fireFiltering(key, value) {
      this.saveFilter(key, value); //filter saved date
      
      let filter = this.filterOrder; 
      filter.startDate = this.startDate;
      filter.endDate = this.endDate;
      filter.sapDo = this.search
      filter.sapSo = this.soNumber
      filter.customerPo = this.customerPo
      filter.status = this.selectedStatus
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const minDate = new Date(this.endDate)
      minDate.setMonth(minDate.getMonth() - 1)
      minDate.setDate(minDate.getDate())
      this.minmin = minDate

      this.busyLoading = true;
      this.deliveryList({ ...filter,entry: this.perPage,page:1}).then((data) => {
        this.processMetadata(data.metadata)
      });
    },
    sapDoFiltering: lodash.debounce(function (value){
      this.search=value;
      this.currentPage=1;

      this.deliveryList({
        startDate : this.startDate, 
        endDate : this.endDate,
        page: this.currentPage, 
        entry: this.perPage,
        sapSo: this.soNumber,
        sapDo:this.search,
        customerPo: this.customerPo,
        status : this.selectedStatus,
       
      }).then((data) => this.processMetadata(data.metadata));
    },500),

    sapSoFiltering: lodash.debounce(function (value){
      this.soNumber=value;
      this.currentPage=1;

      this.deliveryList({
        startDate : this.startDate, 
        endDate : this.endDate,
        page: this.currentPage,
        entry: this.perPage,
        sapDo:this.search,
        sapSo: this.soNumber,
        customerPo: this.customerPo,
        status : this.selectedStatus,
       
      }).then((data) => this.processMetadata(data.metadata));
    },500),

    customerPoFiltering: lodash.debounce(function (value){
      this.customerPo=value;
      this.currentPage=1;

      this.deliveryList({
        startDate : this.startDate, 
        endDate : this.endDate,
        page: this.currentPage,
        entry: this.perPage,
        sapDo:this.search,
        sapSo: this.soNumber,
        customerPo: this.customerPo,
        status : this.selectedStatus
      }).then((data) => this.processMetadata(data.metadata));
    },500),

    statusFiltering: lodash.debounce(function (value){
      this.selectedStatus=value;
      this.currentPage=1;
      this.deliveryList({
        startDate : this.startDate, 
        endDate : this.endDate,
        page: this.currentPage,
        entry: this.perPage,
        sapDo:this.search,
        sapSo: this.soNumber,
        customerPo: this.customerPo,
        status : this.selectedStatus
      }).then((data) => this.processMetadata(data.metadata));
    },500),

    dates(date) {
      return dateFormat(date);
    },

    toDetailPage(data) {
      let dataDo = data.sapDo;
      this.$router.push({
        name: "detail-do-report",
        params: { sapDo:  dataDo},
      });
    },

  },
  created() {
    // Setup Store To Save Filtering Information
    this.getFilterInventory('report-delivery-list');

    let now = new Date();
    let yearAgo = new Date(now.getFullYear()-1, now.getMonth()+1, now.getDate())
    
    let monthAgo= new Date();
    monthAgo.setMonth(monthAgo.getMonth()-1);

    
    this.maxDate = now;
    this.startDate = monthAgo;
    this.endDate = now

    document.title = 'Inventory Report | RSP'

    this.deliveryList({
      startDate : this.startDate, 
      endDate : this.endDate,
      page: this.currentPage, 
      entry: this.perPage,
       
      }).then((data) => this.processMetadata(data.metadata));
  },
}
</script>
